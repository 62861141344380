<template>
  <Layout>
    <el-main class="center-container-MT">
      <div class="center-layout-MT">
        <div class="body-title-layout" style="padding-left: 0">
          <div class="divider-3"></div>
          <div class="body-preview-title-content-style">测试历史</div>
        </div>
        <el-scrollbar class="record-items-container">
          <div class="record-item-container"
               v-for="(i,index) in examList"
               :key="index"
               :style="index===0?'margin-top:0;':'margin-top: 25px;'">
            <div class="time-record-layout">
              <div class="origin-style-TR"></div>
              <div class="time-content-TR">{{ i.time }}</div>
            </div>
            <div class="test-record-Info-container">
              <div class="score-layout-TRI">
                <span class="score-style">{{ i.score }}</span><span style="color: #B4B4B4;">分</span>
              </div>
              <div class="test-problem-layout-TRI">
                <span class="type-TR-TRI">{{ i.type }}</span><br>
                <span class="content-TR-TRI">{{ i.examName }}</span>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </el-main>
  </Layout>
</template>
<script>
import Layout from "@/components/Layout"

export default {
  name: "PersonalCenter",
  components: {Layout},
  data() {
    return {
      isPersonalCenterShow: true,
      isMyTestShow: false,
      isAnswerShow: false,
      examList: []
    }
  },
  methods: {
    getAnswerContent(id) {
      this.$router.push({path: '/answer', query: {id: id}})
    },
  },
  mounted() {
  }
}
</script>

<style>
</style>
